import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c('div',[(_vm.organizations.length)?_c(VCard,{staticClass:"mx-auto mt-2",attrs:{"flat":"","width":"650","color":"primary-light"}},[_c(VCardTitle,[_vm._v(" My Organizations "),_c(VSpacer),(_vm.$store.getters.isAdmin)?_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"primary","to":"/organizations/new","outlined":"","small":_vm.$vuetify.breakpoint.xs}},[_vm._v("New Organization")]):_vm._e()],1),_c(VContainer,[_c(VList,{attrs:{"nav":"","color":"primary-light"}},[_vm._l((_vm.myOrganizations),function(org){return _c(VListItem,{key:org.id,class:{ 'v-list-item--active': org.id === _vm.$store.state.loggedInAs.id },attrs:{"to":("/organizations/" + (org.id)),"color":"primary"}},[(org.key)?_c(VListItemAvatar,[_vm._v(" "+_vm._s(org.key)+" ")]):_vm._e(),_c(VListItemTitle,[_vm._v(" "+_vm._s(org.name)+" ")]),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","color":"primary"}},[_c(VIcon,[_vm._v("mdi-cog")])],1)],1)],1)}),(_vm.myOrganizations.length)?_c(VDivider,{staticClass:"my-2"}):_vm._e(),_vm._l((_vm.addedOrganizations),function(org){return _c(VListItem,{key:org.id,class:{ 'v-list-item--active': org.id === _vm.$store.state.loggedInAs.id },attrs:{"to":("/organizations/" + (org.id)),"color":"primary"}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(org.name)+" ")]),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","color":"primary"}},[_c(VIcon,[_vm._v("mdi-cog")])],1)],1)],1)})],2)],1)],1):(_vm.loadingOrganizations)?_c('div',{staticClass:"text-center"},[_c('BaseProgressCircular')],1):_c(VAlert,{staticClass:"mx-auto mt-4",attrs:{"type":"info","max-width":"500"}},[_vm._v(" You are not a member of any organizations. ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }