<template>
  <v-main>
    <v-container>
      <!-- <div class="d-flex align-center">
        <h1 class="display-1">My Organizations</h1>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          to="/organizations/new"
          class="text-capitalize"
          outlined
          :small="$vuetify.breakpoint.xs"
          >New Organization</v-btn
        >
      </div> -->
      <div>
        <v-card
          class="mx-auto mt-2"
          flat
          width="650"
          v-if="organizations.length"
          color="primary-light"
        >
          <v-card-title>
            My Organizations
            <v-spacer> </v-spacer>
            <v-btn
              v-if="$store.getters.isAdmin"
              color="primary"
              to="/organizations/new"
              class="text-capitalize"
              outlined
              :small="$vuetify.breakpoint.xs"
              >New Organization</v-btn
            >
          </v-card-title>
          <v-container>
            <v-list nav color="primary-light">
              <v-list-item
                :class="{ 'v-list-item--active': org.id === $store.state.loggedInAs.id }"
                v-for="org in myOrganizations"
                :key="org.id"
                :to="`/organizations/${org.id}`"
                color="primary"
              >
                <v-list-item-avatar v-if="org.key">
                  {{ org.key }}
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ org.name }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon color="primary">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="myOrganizations.length" class="my-2"></v-divider>
              <v-list-item
                v-for="org in addedOrganizations"
                :class="{ 'v-list-item--active': org.id === $store.state.loggedInAs.id }"
                :key="org.id"
                :to="`/organizations/${org.id}`"
                color="primary"
              >
                <v-list-item-title>
                  {{ org.name }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon color="primary">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-container>
        </v-card>
        <div class="text-center" v-else-if="loadingOrganizations">
          <BaseProgressCircular />
        </div>
        <v-alert v-else type="info" max-width="500" class="mx-auto mt-4">
          You are not a member of any organizations.
        </v-alert>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from 'vuex';
import accMixin from '../../mixins/account';
export default {
  mixins: [accMixin],
  created() {
    this.getOrganizations();
  },
  computed: {
    ...mapState(['organizations', 'loadingOrganizations']),
    myOrganizations() {
      return this.organizations.filter(org => !org.added);
    },
    addedOrganizations() {
      return this.organizations.filter(org => !!org.added);
    }
  }
};
</script>

<style>
</style>
